import {
  Column,
  FadeSwiper,
  Flex,
  H3,
  H4,
  PriceSubSection,
  Row,
  SEO,
  SubLightSection,
  Swiper,
  Wrapper,
} from '@renderbus/common/components'
import HomeIcon1 from '../images/index/icons/home-01'
import HomeIcon2 from '@renderbus/common/components/svg/home-02'
import HomeIcon4 from '@renderbus/common/components/svg/home-04'
import HomeIcon5 from '@renderbus/common/components/svg/home-05'
import HomeIcon6 from '@renderbus/common/components/svg/home-06'
import HomeIcon8 from '@renderbus/common/components/svg/home-08'
import { graphql, Link } from 'gatsby'
import React from 'react'
import useMedia from 'use-media'

import AnimaSVG from '../images/index/icons/anima.svg'
import ArnoldIcon from '../images/index/icons/arnold.png'
import BlenderIcon from '../images/index/icons/blender.png'
import C4DIcon from '../images/index/icons/c4d.png'
import CoronaIcon from '../images/index/icons/corona.png'
import ForestPackIcon from '../images/index/icons/forest-pack.webp'
import MaxIcon from '../images/index/icons/max.png'
import MayaIcon from '../images/index/icons/maya.png'
import RailCloneIcon from '../images/index/icons/rail-clone.webp'
import Xparticles from '../images/index/icons/xparticles.png'
import MiarmyIcon from '../images/index/icons/miarmy.png'
import RedshiftIcon from '../images/index/icons/redshift.png'
import RenderManIcon from '../images/index/icons/render-man.png'
import OctaneIcon from '../images/index/icons/octane.png'
import VrayIcon from '../images/index/icons/v-ray.png'
import WorldMap from '../images/index/world-map.png'
import UnrealEngine from '../images/index/icons/unreal-engine.png'
import Nuke from '../images/index/icons/nuke.png'
import Katana from '../images/index/icons/katana.png'

import CaseVideo from '../molecules/index/case-video'
import Endorsement from '../molecules/index/endorsement'
import NewCardList from '../molecules/index/news-card-list'
import RenderCard from '../molecules/index/render-card'
import Spotlight from '../molecules/index/spotlight'
import UserEvaluate from '../molecules/index/user-evaluate'
import Layout from '../molecules/layout'
import RegisterBanner from '../molecules/register-banner'
// import { DingdingBanner } from '../molecules/banner'
import { baseLink } from '@renderbus/common/service'

import {
  BannerAside,
  DesktopOnlyPrompt,
  PlatformCard,
  SectionTitle,
  SoftwareBG,
  SoftwareItem,
  SoftwareListContainer,
  SoftwareTips,
  VerticalDivider,
  WorldMapContainer,
  BannerWrapper,
  BannerAsideItem,
} from './index.atom'

import {
  CloudRenderBanner,
  // RCBanner,
  // IPBanner,
  QingyunBanner,
  // XgtUpgradeBanner,
  // EducationDiscountPlanBanner,
  // NewUserCouponBnaner,
  // RaytoolsBanner,
  // PicturePriceDownBanner,
  AnimationMiddleBanner,
  // Summer2023ArchiveExampleBanner,
  // PicturePriceGiftBanner,
  // XgtUpgradeBanner,
  YearEndRecharge2023Banner,
  // AIBanner,
  // RC2024Banner,
  // PictureClientBanner,
  // FreeSampleBanner,
  // AnimationActivityBanner,
  // PictureUserActivityBanner,
  // AnimationGraduationBanner,
  // GiveBackOldUserBanner,
  // AnimationExtremeSpeedBanner,
  MidYearRecharge2024XgtBanner,
  // AIBanner,
  RC2025Banner,
  DoubleElevenBanner,
} from '../molecules/banner'

const SoftwareList = [
  {
    name: 'Maya',
    icon: MayaIcon,
    alt: 'Maya云渲染',
    style: { width: '45px' },
  },
  {
    name: '3ds Max',
    icon: MaxIcon,
    alt: '3ds Max云渲染',
    style: { width: '45px' },
  },
  {
    name: 'Cinema 4d',
    icon: C4DIcon,
    alt: 'Cinema 4d云渲染',
  },
  {
    name: 'Blender',
    icon: BlenderIcon,
    alt: 'Blender云渲染',
  },
  {
    name: 'Unreal Engine',
    icon: UnrealEngine,
    alt: 'Unreal Engine云渲染',
  },
  {
    name: 'V-Ray',
    icon: VrayIcon,
    alt: 'Vray云渲染',
  },
  {
    name: 'Redshift',
    icon: RedshiftIcon,
    alt: 'Redshift云渲染',
  },
  {
    name: 'Arnold',
    icon: ArnoldIcon,
    alt: 'Arnold云渲染',
    style: { width: '45px' },
  },
  {
    name: 'Corona',
    icon: CoronaIcon,
    alt: 'Corona云渲染',
  },
  {
    name: 'Nuke',
    icon: Nuke,
    alt: 'Nuke云渲染',
    style: { width: '52px' },
  },
  {
    name: 'Katana',
    icon: Katana,
    alt: 'Katana云渲染',
    style: { width: '52px' },
  },
  {
    name: 'Octane',
    icon: OctaneIcon,
    alt: 'Octane云渲染',
    style: { height: '52px' },
  },
  {
    name: 'RenderMan',
    icon: RenderManIcon,
    alt: 'RenderMan云渲染',
  },
  {
    name: 'anima®',
    icon: AnimaSVG,
    alt: 'anima云渲染',
    style: { height: '50px' },
  },
  {
    name: 'Forest Pack',
    icon: ForestPackIcon,
    alt: 'Forest Pack云渲染',
  },
  {
    name: 'RailClone',
    icon: RailCloneIcon,
    alt: 'RailClone云渲染',
  },
  {
    name: 'X-Particles',
    icon: Xparticles,
    alt: 'X-Particles云渲染',
  },
  {
    name: 'Miarmy',
    icon: MiarmyIcon,
    alt: 'Miarmy云渲染',
  },
]
const SwiperSwitch = ({ children, ...rest }) => {
  const isWide = useMedia({ minWidth: 768 })
  const onlineActivity = React.Children.map(children, (child) => {
    if (child) {
      return child
    }
  })
  return isWide ? (
    <FadeSwiper {...rest}>{onlineActivity}</FadeSwiper>
  ) : (
    <Swiper {...rest}>{onlineActivity}</Swiper>
  )
}
var moment = require('moment')
const Index = (props) => {
  const {
    location,
    data,
    pageContext: { topThreeShare, newsCardList },
  } = props
  const isMobil = useMedia({ maxWidth: 812 })
  const seoTitle = 'Renderbus云渲染农场-海量机器云渲染平台,高效3D云渲染服务'
  const upDate = topThreeShare && topThreeShare.length && moment(topThreeShare[0].updatedAt)
  const animationMidYearActivity =
    Date.now() > +new Date('Thu May 16 2024 10:00:00 GMT+0800') &&
    Date.now() <= +new Date('Fri Jun 28 2024 12:00:00 GMT+0800')
  const xgtMidYearActivity =
    Date.now() > +new Date('Thu Jun 7 2024 00:00:00 GMT+0800') &&
    Date.now() <= +new Date('Fri Jul 7 2024 00:00:00 GMT+0800')
  return (
    <Layout
      location={location}
      topThreeShare={topThreeShare}
      friendLinks={data.friendLinks.nodes}
      seoTitle={seoTitle}
      upDate={upDate}
    >
      <SEO
        title={seoTitle}
        keywords='云渲染,渲染农场,renderbus'
        description='Renderbus云渲染农场,24H自助式云渲染平台/奥斯卡级专业云渲染农场服务团队,全面支持3dsMax,Maya,Vray,Arnold等软件及插件,提供免费在线云渲染体验,渲染低至0.03元,超40万渲染农场用户的选择！'
      />

      <div style={{ position: 'relative' }}>
        <SwiperSwitch
          navBottom={180}
          // interval={700000}
        >
          {/** 云渲染(常规banner) */}
          <CloudRenderBanner />
          <YearEndRecharge2023Banner />
          {/* <AnimationExtremeSpeedBanner /> */}
          <RC2025Banner />
          <DoubleElevenBanner />
          {/* <AIBanner /> */}
          {/* 动画年中活动 */}
          {animationMidYearActivity && <AnimationMiddleBanner />}
          {/* 动画疾速渲染 */}
          {xgtMidYearActivity && <MidYearRecharge2024XgtBanner />}
          {/* 动画毕设活动 */}
          {/* <AnimationGraduationBanner /> */}
          {/* 免费渲染小样 */}
          {/* <FreeSampleBanner /> */}
          {/* {<RC2024Banner />} */}
          {/* 动画用户活动 */}
          {/* <AnimationActivityBanner /> */}
          {/* 效果图用户活动 */}
          {/* <PictureUserActivityBanner /> */}
          {/* <GiveBackOldUserBanner />  */}
          {/** 青云平台 */}
          <QingyunBanner />
          {/* AI加速 */}
          {/* <AIBanner /> */}
          {/* 效果图降价 */}
          {/* <PicturePriceDownBanner /> */}
          {/* 效果图福利 */}
          {/* {pictureGiftActivity ? <PicturePriceGiftBanner /> : <PicturePriceDownBanner />} */}
          {/* <PicturePriceGiftBanner /> */}
          {/* 暑期档banner */}
          {/* <Summer2023ArchiveExampleBanner /> */}
          {/* 效果图服务升级 */}
          {/* <XgtUpgradeBanner /> */}
          {/* 钉钉 */}
          {/* <DingdingBanner /> */}
          {/** 教育优惠 */}
          {/* <EducationDiscountPlanBanner /> */}

          {/* 瑞兔百宝箱 */}
          {/* <RaytoolsBanner /> */}

          {/* 新用户送券 */}
          {/* <NewUserCouponBnaner /> */}

          {/** 效果图年终活动 */}
          {/* <PictureBanner /> */}

          {/** 效果图客户端上新 */}
          {/* <NewClientBanner /> */}

          {/** 效果图客户端上新 */}
          {/* <PictureClientBanner /> */}

          {/** 动画年终活动 */}
          {/*<YearEndRecharge2022Banner />*/}

          {/* 渲染大赛 */}
          {/* <RCBanner /> */}

          {/** 版权登记 */}
          {/* <IPBanner /> */}
        </SwiperSwitch>
        <BannerAside>
          <BannerWrapper>
            <Flex halign='space-evenly'>
              <BannerAsideItem as={Link} to='/architectural-rendering.html'>
                <HomeIcon1 alt='效果图云渲染农场' />
                <div>
                  <H3>
                    效果图用户
                    <br />
                    注册享
                  </H3>
                  <p>免费渲小样+送45元多重好礼</p>
                </div>
              </BannerAsideItem>
              <VerticalDivider />
              <BannerAsideItem as='a' href='https://www.renderbus.com/ysdh-xrj-lqzn.html'>
                <HomeIcon8 alt='动画云渲染' />
                <div>
                  <H3>
                    动画用户
                    <br />
                    注册福利
                  </H3>
                  <p>送100元云渲染券+限时5折渲染</p>
                </div>
              </BannerAsideItem>
              <VerticalDivider />
              <BannerAsideItem as={Link} to='/promotion.html'>
                <HomeIcon2 alt='云渲染优惠活动' />
                <div>
                  <H3>活动资讯</H3>
                  <p>近期优惠活动</p>
                </div>
              </BannerAsideItem>
            </Flex>
          </BannerWrapper>
        </BannerAside>
      </div>
      <PriceSubSection>
        <Wrapper>
          <SectionTitle>更快、更稳定的云渲染平台</SectionTitle>
          <Row>
            <Column xs='4'>
              <PlatformCard>
                <HomeIcon4 alt='云渲染资源' />
                <H4>高弹性渲染资源</H4>
                <DesktopOnlyPrompt>
                  强大的单集群超1W节点的云渲染平台
                  <br />
                  灵活调度，随时可用
                </DesktopOnlyPrompt>
              </PlatformCard>
            </Column>
            <Column xs='4'>
              <PlatformCard>
                <HomeIcon5 alt='云渲染数据' />
                <H4>数据高度安全</H4>
                <DesktopOnlyPrompt>
                  瑞云渲染平台通过MPA旗下的TPN权威认证
                  <br />
                  数据中心通过ISO27001安全认证
                  <br />
                  拥有覆盖整个行业全球性内容安全保护计划
                </DesktopOnlyPrompt>
              </PlatformCard>
            </Column>
            <Column xs='4'>
              <PlatformCard>
                <HomeIcon6 alt='云渲染农场集成' />
                <H4>无缝集成</H4>
                <DesktopOnlyPrompt>
                  在制作流程中集成Renderbus云渲染
                  <br />
                  使用SDK几行代码即可实现
                </DesktopOnlyPrompt>
              </PlatformCard>
            </Column>
          </Row>
        </Wrapper>
      </PriceSubSection>
      <SubLightSection>
        <Wrapper>
          <SectionTitle>兼容大部分主流软件和插件</SectionTitle>
          <SoftwareListContainer>
            {SoftwareList.map((soft, index) => (
              <SoftwareItem direction='column' style={isMobil ? {} : { width: 'auto' }} key={index}>
                <SoftwareBG>
                  <img src={soft.icon} alt={soft.name} style={soft.style} />
                </SoftwareBG>
                <p style={{ color: 'white' }}>{soft.name}</p>
              </SoftwareItem>
            ))}
            <SoftwareTips>
              <a href={`${baseLink()}/sso/register`} target='blank'>
                可上传自定义插件
              </a>
            </SoftwareTips>
          </SoftwareListContainer>
        </Wrapper>
      </SubLightSection>
      <PriceSubSection>
        <Wrapper>
          <SectionTitle>影视视效/三维动画/设计效果图 一站渲染</SectionTitle>
          <Row spacing={1.5}>
            <Column xs='12' lg='4'>
              <RenderCard
                alt='影视视效渲染农场'
                title='影视视效'
                subTitle='专属团队 定制服务'
                desc='瑞云渲染案例包括口碑佳作《长津湖》《长津湖之水门桥》《战狼2》《唐人街探案2》、影史科幻巨制《流浪地球》《流浪地球2》、印度年度票房冠军《巴霍巴利王2》……'
                thumbnail={data.renderCard1.childImageSharp.fluid}
                link='/film-tv-rendering.html'
              />
            </Column>
            <Column xs='12' lg='4'>
              <RenderCard
                alt='三维动画渲染农场'
                title='三维动画'
                subTitle='VIP价格，尊享渲染'
                desc='瑞云渲染案例包括奥斯卡最佳动画短片《哈布洛先生》、中国动画影史票房神话《哪吒之魔童降世》、口碑佳片《三个机器人》《大圣归来》《深海》《熊出没》《落凡尘》系列电影……'
                thumbnail={data.renderCard2.childImageSharp.fluid}
                link='/film-tv-rendering.html'
              />
            </Column>
            <Column xs='12' lg='4'>
              <RenderCard
                alt='效果图渲染农场'
                title='效果图'
                subTitle='一键发布，快速出图'
                desc='Learn V-Ray，丝路视觉等大型CG培训及3D模型资源网官方指定合作渲染农场'
                thumbnail={data.renderCard3.childImageSharp.fluid}
                link='/architectural-rendering.html'
              />
            </Column>
          </Row>
        </Wrapper>
      </PriceSubSection>
      <SubLightSection style={{ position: 'relative' }}>
        <WorldMapContainer>
          <img src={WorldMap} alt='Renderbus云渲染农场用户覆盖50多个国家和地区' />
        </WorldMapContainer>
        <Wrapper>
          <SectionTitle>奥斯卡幕后团队时刻为您服务</SectionTitle>
          <Spotlight />
          <Endorsement />
        </Wrapper>
      </SubLightSection>
      <CaseVideo />
      <SubLightSection>
        <SectionTitle>用户评价</SectionTitle>
        <UserEvaluate />
      </SubLightSection>
      <PriceSubSection>
        <Wrapper>
          <SectionTitle>瑞云渲染热点资讯</SectionTitle>
          <NewCardList newsList={newsCardList} />
        </Wrapper>
      </PriceSubSection>
      <RegisterBanner isNoLimit />
    </Layout>
  )
}

export default Index

export const query = graphql`
  query {
    renderCard1: file(relativePath: { regex: "/index/render-card1.png/" }) {
      ...fluidImage
    }
    renderCard2: file(relativePath: { regex: "/index/render-card2.png/" }) {
      ...fluidImage
    }
    renderCard3: file(relativePath: { regex: "/index/render-card3.png/" }) {
      ...fluidImage
    }
    friendLinks: allContentfulUsefulLinks(sort: { fields: createdAt }) {
      nodes {
        name
        link
      }
    }
  }
`
